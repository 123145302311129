<template>
  <div class="container flex min-h-screen flex-col">
    <header class="flex items-center justify-between p-4">
      <Icon name="igloo:igloo-text" size="30" />
      <ui-i18n-language-switch />
    </header>

    <main class="flex flex-1 flex-col">
      <slot />
    </main>
  </div>
</template>
